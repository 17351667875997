<template>
  <TosListVuetify serviceName="yard"  :entityName="entityName" :headers="headers" >
    <template #customFilters="slotProps">
      <v-container fluid>
        <v-row>
          <v-col cols="3">
            <v-text-field :label="tLabel('Code')" v-model="slotProps.data.code" />
          </v-col>
          <v-col cols="3">
            <v-text-field :label="tLabel('Description')" v-model="slotProps.data.description" />
          </v-col>
        </v-row>
      </v-container>
    </template>

    <template #editF="slotProps">
      <div v-if="slotProps.data" class="p-cardialog-content">
        <v-row>
            <v-col cols="4">
              <v-text-field  :label="tLabelRequired('Code')" v-model="slotProps.data.code" :rules="[rules.required]" :error-messages="slotProps.errors.code"/>
            </v-col>
            <v-col cols="6">
              <v-text-field  :label="tLabel('Description')" v-model="slotProps.data.description" :error-messages="slotProps.errors.description" />
            </v-col>
        </v-row>      
      </div>
    </template>
  </TosListVuetify>
</template>

<script>
import TosListVuetify from '@/components/anagrafiche/TosListVuetify';
import FormatsMixins from "@/mixins/FormatsMixins";

export default {
  name: "DamageTypes",
  data() {
    return {
      entityName: "damage-types",
      headers: [
        { text: "", value: "action" },
        { text: `${this.tLabel('Code')}`, value: "code"},
        { text: `${this.tLabel('Description')}`, value: "description" },
      ],
    };
  },
  components: {
    TosListVuetify: TosListVuetify
  },
  mixins: [FormatsMixins],
  methods: {
    
  },
};
</script>
